import React from "react";
import cn from "classnames";
import {DECLINED, visitNoteStatuses} from "../../../constants";
import { Group } from "@material-ui/icons";
import {durationInSec, getTimeInFormat12} from "../../../utils/date";
import {Tooltip} from "../../Common/Tooltip";
import moment from "moment";

export const CalendarEventDoctor = props => {
  const membersAmount = props.event.data.requests_in_group;
  const isGroup = membersAmount >= 1;
  const isMultiple = props.event.data.is_grouped;
  const requestsAmount = props.event.data.requests_count;
  const isMyRequest = props.user.uuid === props.event.data.doctor_uuid;
  const isNotAssigned = !props.event.data.doctor_uuid
  const isDeclined =
    props.event.data.service_request_status &&
    props.event.data.service_request_status.toLowerCase() === DECLINED;
  const notSelected = props.selectedEvent?.uuid && props.selectedEvent?.uuid !== props.event.data.uuid;
  const onClick = () => props.action(props.event.data);
    console.log(props.event);
  const isListEvent = props.event.start === undefined && props.event.end === undefined;
  const endDate = props.event.end?.getDate() !== props.event.start?.getDate() ? moment(props.event.start).endOf("day") : props.event.end;
  const duration = durationInSec({start: props.event.start, end: endDate});
  const isMinifiedEvent = !isListEvent && duration <= 2700;
    const date = `${getTimeInFormat12(props.event.start)} - ${getTimeInFormat12(props.event.end)}`
  return (
      <Tooltip title={date}>
    <div
      className={cn("shedule-container-event-doctor", {
        "shedule-container-event-doctor_is-my": isMyRequest,
        "shedule-container-event-doctor_assigned-not-my": !isMyRequest && !isNotAssigned,
        "shedule-container-event-doctor_is-not-assigned": isNotAssigned,
        "shedule-container-event-doctor_declined": isDeclined,
        "shedule-container-event-doctor_not-selected": notSelected,
        "shedule-container-event-doctor_is-minified": isMinifiedEvent,
      })}
      onClick={onClick}
    >
      {isMultiple ? (
        <>
          <div className="shedule-container-event-doctor_amount">
            <span className="shedule-container-event-doctor_amount-wrapper">
              {`${requestsAmount} requests`}
            </span>
          </div>
        </>
      ) : (
        <>
          <div className="shedule-container-event-doctor_header">
            {isGroup && (
              <div className="shedule-container-event-doctor_icon">
                <Group style={{ fontSize: 16 }} />
                <span>({membersAmount})</span>
              </div>
            )}
              {!isMinifiedEvent && <span className="shedule-container-event-doctor_status">
              {visitNoteStatuses[props?.event.data?.service_request_status?.toLowerCase()]?.statusView}
            </span>}
          </div>
          <span className="shedule-container-event-doctor_patient">
            {props.event.data.patient_name}
          </span>
        </>
      )}
      {props.event.data?.service_request_chat?.has_unread_messages && isMyRequest && <div className='shedule-container-event-doctor_is-mess'/>}
    </div>
      </Tooltip>
  );
};
